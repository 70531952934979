<template>
  <div class="StoreEntrance">
    <router-link :to="{ path: '/riichicitystore' }" class="to_shop">
      <img :src="shopImgSrc" alt="" />
      <img :src="shopLabelImgSrc" alt="" class="to_shop_label" />
    </router-link>
    <router-link
      :to="{ path: '/riichicitystore', query: { path: '/pray/recharge' } }"
      class="to_pray"
    >
      <img :src="rechargePrayImgSrc" alt="" />
    </router-link>
    <router-link
      :to="{ path: '/riichicitystore', query: { path: '/sudoku' } }"
      class="to_sudoku"
    >
      <img :src="sudokuImgSrc" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    shopImgSrc() {
      return require(`@/assets/image/home/store_entrance/to_shop_${this.$store.getters.language}.png`);
    },
    shopLabelImgSrc() {
      return require(`@/assets/image/home/store_entrance/to_shop_label_${this.$store.getters.language}.png?v=2`);
    },
    rechargePrayImgSrc() {
      return require(`@/assets/image/home/store_entrance/to_rechargepray_${this.$store.getters.language}.png`);
    },
    sudokuImgSrc() {
      return require(`@/assets/image/home/store_entrance/to_sudoku_${this.$store.getters.language}.png`);
    },
  },
};
</script>
<style lang="scss" scoped>
.StoreEntrance {
  position: fixed;
  right: 0;
  bottom: 15vh;
  z-index: 9;
  img {
    width: 100%;
  }
  a {
    display: block;
    width: 142px;
  }
  .to_shop {
    position: relative;
    margin-bottom: 30px;
    .to_shop_label {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }
  .to_sudoku {
    margin-top: 30px;
  }
}
// 移动端
@media screen and (max-width: 768px) {
  .StoreEntrance {
    top: calc(75vh - 19vw - 30px);
    transform: translateY(-50%);
    a {
      width: 19vw;
    }
  }
}
</style>
