<template>
  <div class="home" :class="{ hide_float: isScreenshot }">
    <!-- <GetGift :isBuySkin="true" />
    <GetGift /> -->
    <StoreEntrance />
    <v-row class="text-center ma-0">
      <v-col
        class="topBanner"
        :class="[{ event: isShowEvent }, this.$store.state.language]"
        cols="12"
      >
        <div class="main_logo">
          <img :src="logoSrc" alt="" srcset="" />
        </div>
        <top-banner />
      </v-col>
      <v-col class="blackBorad">
        <v-container class="topContainer">
          <div class="video-top">
            <div class="rightRole">
              <img
                class="hidden-sm-and-down"
                src="/static/img/home/middle/role01.png?v=20211203"
              />
            </div>
            <v-lazy
              :min-height="200"
              :options="{ threshold: 0.5 }"
              transition="fade-transition"
            >
              <div class="videoDialog">
                <v-dialog v-model="dialog" @click:outside="stopVideo()">
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      class="borderImg"
                      src="/static/img/home/middle/videoPoster.jpg?v=20230704"
                    />
                    <img
                      class="startPlay"
                      src="/static/img/home/middle/startPlay.png"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <div class="videoPlayer">
                    <video-player
                      class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :options="videoOptions"
                      :playsinline="true"
                    />
                  </div>
                </v-dialog>
              </div>
            </v-lazy>
          </div>
        </v-container>
        <black-board />
        <story-background />
      </v-col>
      <v-col class="middleBottom" cols="12" />
      <v-col class="roleBackground" cols="12">
        <bottom-role roleId="roles30" />
      </v-col>
    </v-row>

    <div class="spring_frame" :class="{ hidden: !showFrame }">
      <div class="content" :class="{ show: showContent }">
        <img
          class="title"
          :src="`/static/img/springFrame/title_${$store.state.language}.png`"
          width="142px"
        />
        <div v-for="(item, key) in community_media" :key="key">
          <div class="name">
            <p>{{ item.title }}</p>
          </div>
          <a class="media" :href="item.link" target="_blank">
            <img :src="item.path" alt="" />
          </a>
        </div>
      </div>

      <img
        class="community"
        :class="{ show: showCommunity }"
        @click="handleClick"
        :src="`/static/img/springFrame/community_${$store.state.language}.png`"
        width="54px"
        height="418px"
        alt=""
      />

      <div
        class="arrow"
        :class="{ right: showRight }"
        @click="handleClick"
      ></div>
    </div>

    <div>
      <a class="live" href="/streamer" target="_blank">
        <p
          :class="
            $store.getters.language === 'en' ? 'live_title_en' : 'live_title'
          "
        >
          {{ $t("streamer.p2") }}
        </p>
      </a>
    </div>
  </div>
</template>

<script>
import "@/assets/custom-theme.css";
import TopBanner from "@/components/home/TopBanner.vue";
import BlackBoard from "@/components/home/BlackBoard.vue";
import StoryBackground from "@/components/home/StoryBackground.vue";
import BottomRole from "@/components/home/BottomRole.vue";
import { socailMedia } from "../configs/community-media";
// import GetGift from "../components/home/GetGift.vue";
import StoreEntrance from "../components/home/StoreEntrance.vue";
import changeBg from "@/mixins/changeBg";

export default {
  components: {
    TopBanner,
    BlackBoard,
    StoryBackground,
    BottomRole,
    // GetGift,
    StoreEntrance,
  },
  mixins: [changeBg],
  data() {
    return {
      dialog: false,
      videoOptions: {
        autoplay: false,
        controls: true,
        muted: false,
        preload: "auto",
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            src: "https://d3qgi0t347dz44.cloudfront.net/release/windowspack/riichicity_pv.mp4",
            type: "video/mp4",
          },
        ],
        // poster: "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      },
      showFrame: true,
      showRight: false,
      showContent: true,
      showCommunity: false,
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    community_media() {
      return socailMedia[this.$store.state.language];
    },
  },
  methods: {
    stopVideo() {
      this.$refs.videoPlayer.player.pause();
    },
    handleClick() {
      this.showFrame = !this.showFrame;

      if (this.showContent) {
        setTimeout(() => {
          this.showContent = false;
          this.showCommunity = true;
        }, 0);
      } else {
        this.showContent = true;
        this.showCommunity = false;
      }
    },
    handleTransition() {
      alert("fdfd");
      if (this.showContent) {
        this.showContent = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  &.hide_float {
    .StoreEntrance {
      visibility: hidden;
    }

    .spring_frame {
      visibility: hidden;
    }
    .live {
      visibility: hidden;
    }
  }
  .topBanner {
    position: relative;
    height: 800px;
    background-image: url("/static/img/home/top/topBannerSummer.jpg?v=20220629");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    .main_logo {
      display: none;
    }
    &.event {
      background-image: url("../assets/image/home/main_bg/main_bg.png");
      .main_logo {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 680px;
        img {
          width: 100%;
        }
      }
      @media screen and (max-width: 768px) {
        .main_logo {
          display: none;
        }
        background-image: url("../assets/image/home/main_bg/main_bg_mobile_en.png");
        &.tc {
          background-image: url("../assets/image/home/main_bg/main_bg_mobile_tc.png");
        }
        &.jp {
          background-image: url("../assets/image/home/main_bg/main_bg_mobile_jp.png");
        }
        &.kr {
          background-image: url("../assets/image/home/main_bg/main_bg_mobile_kr.png");
        }
      }
    }
  }
  .blackBorad {
    position: relative;
    height: 2150px;
    background-image: url("/static/img/home/middle/background01.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    .topContainer {
      height: 900px;
      .video-top {
        justify-content: center;
        .rightRole {
          position: absolute;
          width: 100%;
          max-width: 720px;
          left: 55%;
          top: 40px;
          z-index: 1;
          img {
            display: block;
            width: 100%;
            height: auto;
            z-index: 1;
          }
        }
      }
    }
  }
  .middleBottom {
    display: none;
  }
  .roleBackground {
    position: relative;
    background-image: url("/static/img/home/bottom/roleBackground.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 980px) {
  .home {
    .blackBorad {
      height: 2000px;
      padding-top: 300px;
      .topContainer {
        height: 400px;
      }
    }
  }
  .rightRole {
    margin: 0 -80% 0 -20%;
  }
}
@media only screen and (max-width: 767px) {
  .home {
    .middleBottom {
      display: none;
    }
    .blackBorad {
      .topContainer {
        height: 400px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .home {
    .topBanner {
      height: 350px;
      z-index: 1;
      // background-image: linear-gradient( transparent 40%, rgba(235,243,245,1) 59%, rgba(236,245,247,1) 100%),
      //                   url('/static/img/home/top/topBannerCenter.jpg');
      // background-size: cover, 770px 75%;
    }
    .blackBorad {
      padding-top: 380px;
      .topContainer {
        height: 500px;
      }
    }
    .middleBottom {
      display: inherit;
      height: 80px;
      background-image: url("/static/img/home/middle/middleBottom.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% 100%;
    }
  }
}
@media only screen and (max-width: 450px) {
  .home {
    .topBanner {
      height: 350px;
      background-position: right top;
    }
    .blackBorad {
      height: 1800px;
      .topContainer {
        height: 330px;
      }
    }
  }
}
</style>
<style lang="scss">
// .v-overlay {
//   z-index: 1009 !important;
// }
// .v-dialog__content {
//   z-index: 1010 !important;
// }

.home .topBanner.event .topic img {
  visibility: hidden !important;
}
.home {
  .blackBorad {
    .videoDialog {
      position: absolute;
      top: 15%;
      left: 15%;
      width: 720px;
      z-index: 2;
      .borderImg {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 0 10%;
        border: 5px solid #7d8dfd;
        border-bottom: 15px solid #7d8dfd;
        z-index: 1;
      }
      .startPlay {
        position: absolute;
        top: 37%;
        right: 35%;
        margin: 0 auto;
        z-index: 2;
      }
    }
  }
}
div.v-dialog {
  width: 50%;
  .videoPlayer {
    .vjs_video_3-dimensions {
      height: 600px !important;
    }
  }
}
@media only screen and (min-width: 2000px) {
  .home {
    .blackBorad {
      .videoDialog {
        left: 20%;
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .home {
    .blackBorad {
      .videoDialog {
        margin-left: 5%;
        width: 600px;
        .borderImg {
          height: 350px;
        }
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .home {
    .blackBorad {
      .videoDialog {
        position: relative;
        margin: 8% auto;
        top: 0;
        left: 0;
        .borderImg {
          width: 80%;
          height: 315px;
          margin: 0 auto;
        }
        .startPlay {
          width: 10%;
          top: 40%;
          right: 45%;
        }
      }
    }
  }
  div.v-dialog {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .home {
    .blackBorad {
      .videoDialog {
        width: 640px;
        .startPlay {
          top: 40%;
          right: 45%;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .home {
    .blackBorad {
      .videoDialog {
        width: 448px;
        margin: 30% auto;
        .borderImg {
          width: 100%;
          height: 270px;
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .home {
    .blackBorad {
      .videoDialog {
        width: 320px;
        text-align: center;
        left: 0;
        .borderImg {
          width: 100%;
          height: 198px;
          z-index: 1;
        }
        .startPlay {
          right: 50%;
        }
      }
    }
  }
  div.v-dialog {
    .videoPlayer {
      .vjs_video_3-dimensions {
        height: 400px !important;
      }
    }
  }
}
.spring_frame {
  position: fixed;
  left: 0px;
  top: 23%;
  width: 180px; //253px;
  height: 578px; //813px;
  background: url("/static/img/springFrame/bg1.png");
  background-size: 100% auto;
  z-index: 9;
  transition: transform 0.6s;
  transform: scale(0.8) translate(-12%, 0px);
  .content {
    display: none;
  }
  .content .name {
    text-align: center;
    p {
      font-size: 20px;
      color: #7d8dfd;
      margin-bottom: 10px;
      font-weight: 900;
    }
  }
  .content.show {
    display: block;
  }
  .community {
    position: relative;
    display: none;
    top: 46%;
    transform: translate(0px, -50%);
    cursor: pointer;
  }
  .community.show {
    display: block;
  }
  .arrow {
    width: 37px;
    height: 58px;
    background-image: url("/static/img/springFrame/arrow-left.png");
    position: absolute;
    right: 0px;
    top: 46%;
    cursor: pointer;
    // transition: transform 0.6s;
  }
  .arrow.right {
    background: url("/static/img/springFrame/arrow-right.png");
  }
  img {
    display: block;
    margin: 0 auto;
  }
  .title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .media {
    position: relative;
    display: block;
    width: 95%;
    margin-top: 10px;
    margin-bottom: 35px;
    img {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  }
}
.spring_frame.hidden {
  transform: scale(0.5) translate(-50%, 0px);
  .arrow {
    transform: rotate(0.5turn);
  }
}
@media screen and (max-width: 600px) {
  .spring_frame {
    top: 50px;
    transform: scale(0.5) translate(-50%, 0px);
  }
  .spring_frame.hidden {
    transform: scale(0.25) translate(-165%, 0px);
    .arrow {
      transform: rotate(0.5turn);
    }
  }
}
.live {
  position: fixed;
  left: 0px;
  top: 10%;
  width: 180px;
  height: 188px;
  text-decoration: none;
  background: url("/static/img/live/live.png");
  background-size: 100% auto;
  z-index: 9;
  transform: scale(0.8) translate(-12.4%, 0px);
  .live_title {
    color: #43135f;
    text-align: center;
    line-height: 60px;
    font-size: 24px;
    font-weight: bold;
    margin-right: 5px;
  }
  .live_title_en {
    color: #43135f;
    text-align: center;
    line-height: 31px;
    font-size: 22px;
    font-weight: bold;
    margin-right: 5px;
  }
}
@media screen and (max-width: 600px) {
  .live {
    top: 45px;
    transform: scale(0.5) translate(-50%, 0px);
  }
}

/* event start */
@media screen and (max-width: 768px) {
  .home {
    .topBanner.event {
      height: 93vw !important;
      .downloadButton {
        margin-top: 10vw !important;
      }
    }
    .blackBorad {
      height: 2200px !important;
      padding-top: 500px !important;
      background-repeat: repeat !important;
    }
  }
}
@media screen and (max-width: 680px) {
  .home {
    .topBanner.event {
      .downloadButton {
        margin-top: 0vw !important;
      }
    }
    .blackBorad {
      padding-top: 560px !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .home {
    .topBanner.event {
      .downloadButton {
        margin-top: 40vw !important;
      }
    }
    .blackBorad {
      height: 2100px !important;
      padding-top: 400px !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .home {
    .topBanner.event {
      .downloadButton {
        // margin-top: 40vw !important;
      }
    }
    .middleBottom {
      display: none !important;
    }
  }
}
@media screen and (max-width: 530px) {
  .home {
    .topBanner.event {
      .downloadButton {
        margin-top: 26vw !important;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .home {
    .topBanner.event {
      .downloadButton {
        margin-top: 20vw !important;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .home {
    .topBanner.event {
      .downloadButton {
        margin-top: 10vw !important;
      }
    }
  }
}
/* event end */
</style>
