// All roles characters
export const rolesList = [
  {
    name: "character.p3",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role01.png",
    href: "/characters/roles01",
    id: "roles01",
    mainImg: "/static/img/roles/gif/role01.gif?v=20240731",
    nickname: "character.p4",
    cvname: "character.p5",
    description: "character.p6",
    character: "home.p16",
    voice: ["一色清美5002.wav", "一色清美5003.wav", "一色清美6001.wav"],
    expression: [
      "/static/img/roles/gif/role01/role01-01.gif",
      "/static/img/roles/gif/role01/role01-03.gif",
      "/static/img/roles/gif/role01/role01-02.gif",
    ],
  },
  {
    name: "character.p7",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role06.png",
    href: "/characters/roles06",
    id: "roles06",
    mainImg: "/static/img/roles/gif/role06.gif?v=20211203",
    nickname: "character.p8",
    cvname: "character.p9",
    description: "character.p10",
    character: "home.p16",
    voice: ["三宅環奈5002.wav", "三宅環奈5003.wav", "三宅環奈6001.wav"],
    expression: [
      "/static/img/roles/gif/role06/role06-01.gif",
      "/static/img/roles/gif/role06/role06-02.gif",
      "/static/img/roles/gif/role06/role06-03.gif",
    ],
  },
  {
    name: "character.p11",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role02.png",
    href: "/characters/roles02",
    id: "roles02",
    mainImg: "/static/img/roles/gif/role02.gif?v=20211203",
    nickname: "character.p12",
    cvname: "character.p13",
    description: "character.p14",
    character: "home.p16",
    voice: ["東方凪5002.wav", "東方凪5003.wav", "東方凪6001.wav"],
    expression: [
      "/static/img/roles/gif//role02/role02-01.gif",
      "/static/img/roles/gif/role02/role02-02.gif",
      "/static/img/roles/gif/role02/role02-03.gif",
    ],
  },
  {
    name: "character.p15",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role05.png",
    href: "/characters/roles05",
    id: "roles05",
    mainImg: "/static/img/roles/gif/role05.gif?v=20211203",
    nickname: "character.p16",
    cvname: "character.p17",
    description: "character.p18",
    character: "home.p16",
    voice: ["五十嵐紗子5002.wav", "五十嵐紗子5003.wav", "五十嵐紗子6001.wav"],
    expression: [
      "/static/img/roles/gif/role05/role05-01.gif",
      "/static/img/roles/gif/role05/role05-02.gif",
      "/static/img/roles/gif/role05/role05-04.gif",
    ],
  },
  {
    name: "character.p19",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role03.png",
    href: "/characters/roles03",
    id: "roles03",
    mainImg: "/static/img/roles/gif/role03.gif?v=20220421",
    nickname: "character.p20",
    cvname: "character.p21",
    description: "character.p22",
    character: "home.p16",
    voice: ["九鬼蓮心5002.wav", "九鬼蓮心5003.wav", "九鬼蓮心6001.wav"],
    expression: [
      "/static/img/roles/gif/role03/role03-01.gif",
      "/static/img/roles/gif/role03/role03-02.gif",
      "/static/img/roles/gif/role03/role03-04.gif",
    ],
  },
  {
    name: "character.p23",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role04.png",
    href: "/characters/roles04",
    id: "roles04",
    mainImg: "/static/img/roles/gif/role04.gif?v=20211203",
    nickname: "character.p24",
    cvname: "character.p25",
    description: "character.p26",
    character: "home.p16",
    voice: ["天海汐月5002.wav", "天海汐月5003.wav", "天海汐月6001.wav"],
    expression: [
      "/static/img/roles/gif/role04/role04-01.gif",
      "/static/img/roles/gif/role04/role04-02.gif",
      "/static/img/roles/gif/role04/role04-05.gif",
    ],
  },
  {
    name: "character.p28",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role07.png",
    href: "/characters/roles07",
    id: "roles07",
    mainImg: "/static/img/roles/gif/role07.gif?v=20211203",
    nickname: "character.p29",
    cvname: "character.p30",
    description: "character.p31",
    character: "home.p16",
    voice: ["天宮健一5002.wav", "天宮健一5003.wav", "天宮健一6001.wav"],
    expression: [
      "/static/img/roles/gif/role07/role07-01.gif",
      "/static/img/roles/gif/role07/role07-02.gif",
      "/static/img/roles/gif/role07/role07-03.gif",
    ],
  },
  {
    name: "character.p32",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role08.png",
    href: "/characters/roles08",
    id: "roles08",
    mainImg: "/static/img/roles/gif/role08.gif?v=20211203",
    nickname: "character.p33",
    cvname: "character.p34",
    description: "character.p35",
    character: "home.p16",
    voice: ["雀丸5002.wav", "雀丸5003.wav", "雀丸6001.wav"],
    expression: [
      "/static/img/roles/gif/role08/role08-01.gif",
      "/static/img/roles/gif/role08/role08-02.gif",
      "/static/img/roles/gif/role08/role08-03.gif",
    ],
  },
  {
    name: "character.p36",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role09.png",
    href: "/characters/roles09",
    id: "roles09",
    mainImg: "/static/img/roles/gif/role09.gif?v=20211203",
    nickname: "character.p37",
    cvname: "character.p38",
    description: "character.p39",
    character: "home.p16",
    voice: [
      "五月女百合香语音4006.wav",
      "五月女百合香语音6001.wav",
      "五月女百合香语音5005.wav",
    ],
    expression: [
      "/static/img/roles/gif/role09/role09-01.gif",
      "/static/img/roles/gif/role09/role09-02.gif",
      "/static/img/roles/gif/role09/role09-03.gif",
    ],
  },
  {
    name: "character.p40",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role10.png",
    href: "/characters/roles10",
    id: "roles10",
    mainImg: "/static/img/roles/gif/role10.gif?v=20211203",
    nickname: "character.p41",
    cvname: "character.p42",
    description: "character.p43",
    character: "home.p16",
    voice: [
      "赤羽雅典娜语音5002.wav",
      "赤羽雅典娜语音5004.wav",
      "赤羽雅典娜语音6001.wav",
    ],
    expression: [
      "/static/img/roles/gif/role10/role10-01.gif",
      "/static/img/roles/gif/role10/role10-02.gif",
      "/static/img/roles/gif/role10/role10-03.gif",
    ],
  },
  {
    name: "character.p44",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role11.png",
    href: "/characters/roles11",
    id: "roles11",
    mainImg: "/static/img/roles/gif/role11.gif?v=20220915",
    nickname: "character.p45",
    cvname: "character.p46",
    description: "character.p47",
    character: "home.p16",
    voice: [
      "夏海真凜语音4009_2.wav",
      "夏海真凜语音5004.wav",
      "夏海真凜语音6001.wav",
    ],
    expression: [
      "/static/img/roles/gif/role11/role11-01.gif",
      "/static/img/roles/gif/role11/role11-02.gif",
      "/static/img/roles/gif/role11/role11-03.gif",
    ],
  },
  {
    name: "character.p48",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role12.png",
    href: "/characters/roles12",
    id: "roles12",
    mainImg: "/static/img/roles/gif/role12.gif?v=20220915",
    nickname: "character.p49",
    cvname: "character.p50",
    description: "character.p51",
    character: "home.p16",
    voice: [
      "五月女直哉语音5001.wav",
      "五月女直哉语音5002.wav",
      "五月女直哉语音5004.wav",
    ],
    expression: [
      "/static/img/roles/gif/role12/role12-01.gif",
      "/static/img/roles/gif/role12/role12-02.gif",
      "/static/img/roles/gif/role12/role12-03.gif",
    ],
  },
  {
    name: "character.p52",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role13.png",
    href: "/characters/roles13",
    id: "roles13",
    mainImg: "/static/img/roles/gif/role13.gif?v=20221215",
    nickname: "character.p53",
    cvname: "character.p54",
    description: "character.p55",
    character: "home.p16",
    voice: ["深瀨愛麗絲4011.wav", "深瀨愛麗絲5004.wav", "深瀨愛麗絲6001.wav"],
    expression: [
      "/static/img/roles/gif/role13/role13-01.gif",
      "/static/img/roles/gif/role13/role13-02.gif",
      "/static/img/roles/gif/role13/role13-03.gif",
    ],
  },
  {
    name: "character.p56",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role14.png",
    href: "/characters/roles14",
    id: "roles14",
    mainImg: "/static/img/roles/gif/role14.gif?v=20221415",
    nickname: "character.p57",
    cvname: "character.p58",
    description: "character.p59",
    character: "home.p16",
    voice: ["梅妮亞4009_1.wav", "梅妮亞5010.wav", "梅妮亞6001.wav"],
    expression: [
      "/static/img/roles/gif/role14/role14-01.gif",
      "/static/img/roles/gif/role14/role14-02.gif",
      "/static/img/roles/gif/role14/role14-03.gif",
    ],
  },
  {
    name: "character.p60",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role15.png",
    href: "/characters/roles15",
    id: "roles15",
    mainImg: "/static/img/roles/gif/role15.gi",
    nickname: "character.p61",
    cvname: "character.p62",
    description: "character.p63",
    character: "home.p16",
    voiceDay: ["AU.RO.RA1_day.wav", "AU.RO.RA2_day.wav", "AU.RO.RA3_day.wav"],
    voiceNight: [
      "AU.RO.RA1_night.wav",
      "AU.RO.RA2_night.wav",
      "AU.RO.RA3_night.wav",
    ],
    expressionDay: [
      `/static/img/roles/gif/role15/role15-01_day.gif`,
      `/static/img/roles/gif/role15/role15-02_day.gif`,
      `/static/img/roles/gif/role15/role15-03_day.gif`,
    ],
    expressionNight: [
      `/static/img/roles/gif/role15/role15-01_night.gif`,
      `/static/img/roles/gif/role15/role15-02_night.gif`,
      `/static/img/roles/gif/role15/role15-03_night.gif`,
    ],
  },
  {
    name: "character.p64",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role16.png",
    href: "/characters/roles16",
    id: "roles16",
    mainImg: "/static/img/roles/gif/role16.gif?v=20221415",
    nickname: "character.p65",
    cvname: "character.p66",
    description: "character.p67",
    character: "home.p16",
    voice: ["不二一気5001.wav", "不二一気5003.wav", "不二一気5004.wav"],
    expression: [
      "/static/img/roles/gif/role16/role16-01.gif",
      "/static/img/roles/gif/role16/role16-02.gif",
      "/static/img/roles/gif/role16/role16-03.gif",
    ],
  },
  {
    name: "character.p68",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role17.png",
    href: "/characters/roles17",
    id: "roles17",
    mainImg: "/static/img/roles/gif/role17.gif?v=20221415",
    nickname: "character.p69",
    cvname: "character.p70",
    description: "character.p71",
    character: "home.p16",
    voice: ["白羽飛鳥5001.wav", "白羽飛鳥5002.wav", "白羽飛鳥5003.wav"],
    expression: [
      "/static/img/roles/gif/role17/role17-01.gif",
      "/static/img/roles/gif/role17/role17-02.gif",
      "/static/img/roles/gif/role17/role17-03.gif",
    ],
  },
  {
    name: "character.p72",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role18.png",
    href: "/characters/roles18",
    id: "roles18",
    mainImg: "/static/img/roles/gif/role18.gif?v=20221415",
    nickname: "character.p73",
    cvname: "character.p74",
    description: "character.p75",
    character: "home.p16",
    voice: ["原道雪5001.wav", "原道雪5002.wav", "原道雪5003.wav"],
    expression: [
      "/static/img/roles/gif/role18/role18-01.gif",
      "/static/img/roles/gif/role18/role18-02.gif",
      "/static/img/roles/gif/role18/role18-03.gif",
    ],
  },
  {
    name: "character.p76",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role19.png",
    href: "/characters/roles19",
    id: "roles19",
    mainImg: "/static/img/roles/gif/role19.gif?v=20221415",
    nickname: "character.p77",
    cvname: "character.p78",
    description: "character.p79",
    character: "home.p16",
    voice: ["音无郁子5001.wav", "音无郁子5002.wav", "音无郁子5003.wav"],
    expression: [
      "/static/img/roles/gif/role19/role19-01.gif",
      "/static/img/roles/gif/role19/role19-02.gif",
      "/static/img/roles/gif/role19/role19-03.gif",
    ],
  },
  {
    name: "character.p80",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role20.png",
    href: "/characters/roles20",
    id: "roles20",
    mainImg: "/static/img/roles/gif/role20.gif?v=20221415",
    nickname: "character.p81",
    cvname: "character.p82",
    description: "character.p83",
    character: "home.p16",
    voice: ["榎宮澪5002.wav", "榎宮澪5003.wav", "榎宮澪6001.wav"],
    expression: [
      "/static/img/roles/gif/role20/role20-01.gif",
      "/static/img/roles/gif/role20/role20-02.gif",
      "/static/img/roles/gif/role20/role20-03.gif",
    ],
  },
  {
    name: "character.p84",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role21.png",
    href: "/characters/roles21",
    id: "roles21",
    mainImg: "/static/img/roles/gif/role21.gif?v=20221415",
    nickname: "character.p85",
    cvname: "character.p86",
    description: "character.p87",
    character: "home.p16",
    voice: ["KokuraKanade5001.wav", "KokuraKanade5002.wav", "KokuraKanade5003.wav"],
    expression: [
      "/static/img/roles/gif/role21/role21-01.gif",
      "/static/img/roles/gif/role21/role21-02.gif",
      "/static/img/roles/gif/role21/role21-03.gif",
    ],
  },
  {
    name: "character.p88",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role22.png",
    href: "/characters/roles22",
    id: "roles22",
    mainImg: "/static/img/roles/gif/role22.gif?v=20221415",
    nickname: "character.p89",
    cvname: "character.p90",
    description: "character.p91",
    character: "home.p16",
    voice: ["mahiru3003.wav", "mahiru5001.wav", "mahiru5010.wav"],
    expression: [
      "/static/img/roles/gif/role22/role22-01.gif",
      "/static/img/roles/gif/role22/role22-02.gif",
      "/static/img/roles/gif/role22/role22-03.gif",
    ],
  },
  {
    name: "character.p92",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role23.png",
    href: "/characters/roles23",
    id: "roles23",
    mainImg: "/static/img/roles/gif/role23.gif?v=20221415",
    nickname: "character.p93",
    cvname: "character.p94",
    description: "character.p95",
    character: "home.p16",
    voice: ["Inukai_Hina3004.wav", "Inukai_Hina5001.wav", "Inukai_Hina6012.wav"],
    expression: [
      "/static/img/roles/gif/role23/role23-01.gif",
      "/static/img/roles/gif/role23/role23-02.gif",
      "/static/img/roles/gif/role23/role23-03.gif",
    ],
  },
  {
    name: "character.p96",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role24.png",
    href: "/characters/roles24",
    id: "roles24",
    mainImg: "/static/img/roles/gif/role24.gif?v=20221415",
    nickname: "character.p97",
    cvname: "character.p98",
    description: "character.p99",
    character: "home.p16",
    voice: ["Tachibana_Kaoru3001.wav", "Tachibana_Kaoru3005.wav", "Tachibana_Kaoru5001.wav"],
    expression: [
      "/static/img/roles/gif/role24/role24-01.gif",
      "/static/img/roles/gif/role24/role24-02.gif",
      "/static/img/roles/gif/role24/role24-03.gif",
    ],
  },
  {
    name: "character.p100",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role25.png",
    href: "/characters/roles25",
    id: "roles25",
    mainImg: "/static/img/roles/gif/role25.gif?v=20221415",
    nickname: "character.p101",
    cvname: "character.p102",
    description: "character.p103",
    character: "home.p16",
    voice: ["Yuzo3002.wav", "Yuzo6001.wav", "Yuzo8003.wav"],
    expression: [
      "/static/img/roles/gif/role25/role25-01.gif",
      "/static/img/roles/gif/role25/role25-02.gif",
      "/static/img/roles/gif/role25/role25-03.gif",
    ],
  },
  {
    name: "character.p104",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role26.png",
    href: "/characters/roles26",
    id: "roles26",
    mainImg: "/static/img/roles/gif/role26.gif?v=20221415",
    nickname: "character.p105",
    cvname: "character.p106",
    description: "character.p107",
    character: "home.p16",
    voice: ["Moegihara_Aoi5001.wav", "Moegihara_Aoi5002.wav", "Moegihara_Aoi5003.wav"],
    expression: [
      "/static/img/roles/gif/role26/01.gif",
      "/static/img/roles/gif/role26/02.gif",
      "/static/img/roles/gif/role26/03.gif",
    ],
  },
  {
    name: "character.p108",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role27.png",
    href: "/characters/roles27",
    id: "roles27",
    mainImg: "/static/img/roles/gif/role27.gif?v=20221415",
    nickname: "character.p109",
    cvname: "character.p110",
    description: "character.p111",
    character: "home.p16",
    voice: ["Kazama_Shizuku5001.wav", "Kazama_Shizuku5002.wav", "Kazama_Shizuku5003.wav"],
    expression: [
      "/static/img/roles/gif/role27/01.gif",
      "/static/img/roles/gif/role27/02.gif",
      "/static/img/roles/gif/role27/03.gif",
    ],
  },
  {
    name: "character.p112",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role28.png",
    href: "/characters/roles28",
    id: "roles28",
    mainImg: "/static/img/roles/gif/role28.gif?v=20221415",
    nickname: "character.p113",
    cvname: "character.p114",
    description: "character.p115",
    character: "home.p16",
    voice: ["Elsie5001.wav", "Elsie5002.wav", "Elsie5003.wav"],
    expression: [
      "/static/img/roles/gif/role28/01.gif",
      "/static/img/roles/gif/role28/02.gif",
      "/static/img/roles/gif/role28/03.gif",
    ],
  },
  {
    name: "character.p116",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role29.png",
    href: "/characters/roles29",
    id: "roles29",
    mainImg: "/static/img/roles/gif/role29.gif?v=20221415",
    nickname: "character.p117",
    cvname: "character.p118",
    description: "character.p119",
    character: "home.p16",
    voice: ["Kisaragi_Lisa5001.wav", "Kisaragi_Lisa5002.wav", "Kisaragi_Lisa5003.wav"],
    expression: [
      "/static/img/roles/gif/role29/01.gif",
      "/static/img/roles/gif/role29/02.gif",
      "/static/img/roles/gif/role29/03.gif",
    ],
  },
  {
    name: "character.p120",
    background: "/static/img/roles/roleFrame.png",
    nameImage: "/static/img/roles/roleNameFrame.png",
    img: "/static/img/roles/role30.png",
    href: "/characters/roles30",
    id: "roles30",
    mainImg: "/static/img/roles/gif/role30.gif?v=20221415",
    nickname: "character.p121",
    cvname: "character.p122",
    description: "character.p123",
    character: "home.p16",
    voice: ["Reizei_Emeko5001.wav", "Reizei_Emeko5002.wav", "Reizei_Emeko5003.wav"],
    expression: [
      "/static/img/roles/gif/role30/01.gif",
      "/static/img/roles/gif/role30/02.gif",
      "/static/img/roles/gif/role30/03.gif",
    ],
  },
];
